export const OfferRechargeB2C = [
  {
    value: '2000',
    label: ' you will get 1% cashback into your wallet',
  },
  {
    value: '5000',
    label: ' you will get 2% cashback into your wallet',
  },
  {
    value: '10000',
    label: ' you will get 3% cashback into your wallet',
  },
  {
    value: '25000',
    label: ' you will get 4% cashback into your wallet',
  },
  {
    value: '50000',
    label: ' you will get 5% cashback into your wallet max ',
  },
  {
    value: '100000',
    label: ' or above will get 6% or max 7000 cashback into your wallet',
  }
];

export const OfferRechargeB2B = [
  {
    value: '10000',
    label: ' you will get 1% cashback into your wallet',
  },
  {
    value: '20000',
    label: ' you will get 2% cashback into your wallet',
  },
  {
    value: '50000',
    label: ' you will get 3% cashback into your wallet',
  },
  {
    value: '75000',
    label: ' you will get 4% cashback into your wallet',
  },
  {
    value: '100000',
    label: ' or above, you will get 5% or max 7000 cashback into your wallet ',
  }
];